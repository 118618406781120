import React from 'react';
import Icon1 from '../assets/icon1.svg';
import Icon2 from '../assets/icon2.svg';
import Icon3 from '../assets/icon3.svg';
import MockImage from '../assets/mock.png';
import { Link } from 'react-router-dom';

const EmployeeBenefits = () => {
  return (
    <div className="benefits-top">
      <img src={MockImage} alt="Mock" className="mock-image" />
      <div className="benefits-content">
        <h2 className='benefit-title'>How We Help</h2>
        <p className="how-we-help">
        Employ our AI as your dedicated sales caller for companies seeking improved sales metrics, or as an efficient AI receptionist for organizations needing skilled frontline support. Let our AI manage your communications for unparalleled productivity and efficacy. </p>
        
        {/* <Link to="/employees" className="learn-more-button">
          Learn More
        </Link> */}
      </div>
    </div>
  );
};

export default EmployeeBenefits;