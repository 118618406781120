import React from 'react';
import Icon4 from '../assets/icon4.svg';
import Icon5 from '../assets/icon5.svg';
import Icon6 from '../assets/icon6.svg';
import MockImage2 from '../assets/mock2.png';
import DemoAudio from '../assets/demo-audio.wav'; 
import { Link } from 'react-router-dom';

const EmployerBenefits = () => {
  return (
    <div className="benefits-bottom">
      <img src={MockImage2} alt="Mock" className="mock-image2" />
      <div className='text-container'>
        <h2 className='benefit-title'>AI Receptionist</h2>
        <div className='another'>
          <p className="subtext">
            An AI receptionist efficiently manages all tasks typically handled by a traditional receptionist, and more. From taking calls to making reservations, providing quotes, and addressing queries, this AI does it all.
          </p>
        </div>
        <audio controls className='audioclips'>
            <source src={DemoAudio} type="audio/wav" /> 
            Your browser does not support the audio element.
          </audio>
      </div>
    </div>
  );
};

export default EmployerBenefits;
