import React from 'react';
import './BenefitsSection.css';
import EmployeeBenefits from './EmployeeBenefits';
import EmployerBenefits from './EmployerBenefits';
import MockImage2 from '../assets/mock2.png';
import MockImage3 from '../assets/mock3.png';
import MockImage4 from '../assets/mock4.png';
import DemoAudio2 from '../assets/demo2fr.wav'; // Import the second audio file

const BenefitsSection = () => {
  return (
    <div className="benefits-section">
      <EmployeeBenefits />
      <EmployerBenefits />
      <div className="benefits-sales">
        <img src={MockImage3} alt="Mock" className="mock-image2" />
        <div className='text-container'>
          <h2 className='benefit-title'>AI Sales Agent</h2>
          <div className='another'>
            <p className="subtext">
              Our AI sales caller performs all the functions that a traditional sales representative does, and beyond. From initiating calls to pitching products or services, providing price quotes, and addressing customer inquiries, this AI handles it all seamlessly.
            </p>
          </div>
          <audio controls className='audioclips'>
            <source src={DemoAudio2} type="audio/wav" /> 
            Your browser does not support the audio element.
          </audio>
        </div>
      </div>
      <div className="benefits-bottom">
        <img src={MockImage4} alt="Mock" className="mock-image2" />
        <div className='text-container'>
          <h2 className='benefit-title'>Custom Solution</h2>
          <div className='another'>
            <p className="subtext">
              With our cutting-edge technology, we offer custom solutions where an AI agent is tailored to handle unique customer-oriented tasks. This state-of-the-art AI system can be programmed to meet specific needs, ensuring best-in-class service and efficiency in any task it undertakes.
            </p>
          </div>
          {/* Assuming you want to use the same audio for this part as well
          <audio controls className='audioclips'>
            <source src={DemoAudio2} type="audio/wav" /> 
            Your browser does not support the audio element.
          </audio> */}
        </div>
      </div>
    </div>
  );
};

export default BenefitsSection;
