import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import LandingPage from './pages/LandingPage';
import Employees from './pages/Employees';
import Employers from './pages/Employers';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/employers" element={<Employers />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;