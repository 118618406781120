import React from 'react';
import Footer from '../components/Footer';
import Icon1 from '../assets/icon1.svg';
import Icon2 from '../assets/icon2.svg';
import Icon3 from '../assets/icon3.svg';
import MockImage from '../assets/mock.png';
import MockImage1 from '../assets/mock1.png';
import MockImage2 from '../assets/mock2.png';
import MockImage3 from '../assets/mock3.png';

const Employees = () => {
  const redirectToTypeform = () => {
    window.open('https://fg3489c1ke9.typeform.com/to/b1PqgAFk', '_blank');
  };

  return (
    <>
    <div className="hero">
      <div className="hero-content">
        <h1 className="hero-title">Get Paid Everyday</h1>
        <p className="hero-subtitle">Unlock Your Earnings On-Demand: Your Hard Work, Available When You Need It Most</p>
        {/* <button className="get-started-btn">Learn More</button> */}
      </div>
    </div>
    <div className="benefits-top">
      <img src={MockImage3} alt="Mock" className="mock-image3" />
      <div className="benefits-content">
        <h2 className='benefit-title'>How It Works</h2>
        <div className="benefits-list">
          <div className="benefit-item">
            <img src={Icon1} alt="Icon 1" className="benefit-icon" />
            <h3>Talk To Your Employer</h3>
            <p>Once your employer signs up, they will provide you a link.</p>
          </div>
          <div className="benefit-item">
            <img src={Icon2} alt="Icon 2" className="benefit-icon" />
            <h3>Create And Connect Your Account</h3>
            <p>You will be given your own account where you can send and recieve money.</p>
          </div>
          <div className="benefit-item">
            <img src={Icon3} alt="Icon 3" className="benefit-icon" />
            <h3>Access Real-time Earnings</h3>
            <p>You're ready to go! Start working and get paid whenever you want. </p>
          </div>
        </div>
        <img src={MockImage2} alt="Mock" className="mock-image4" />
      </div>
    </div>
    <Footer />
    </>
  );
};

export default Employees;