import React from 'react';
import './Logos.css';

// Import your logo images here
// import logo1 from '../assets/logo1.svg';
import logo2 from '../assets/logo2.png';
import logo3 from '../assets/logo3.png';
import logo4 from '../assets/logo4.jpg';
import logo5 from '../assets/logo5.png';

const Logos = () => {
  return (
    <div className="header">
      <h2 className='logo-title'>Our Customers</h2>
      <div className='logos-container'>
      <div className="logo-wrapper">
        <img src={logo2} alt="Logo 2" className="logo" />
      </div>
      <div className="logo-wrapper">
        <img src={logo3} alt="Logo 3" className="logo" />
      </div>
      <div className="logo-wrapper">
        <img src={logo4} alt="Logo 4" className="logo4" />
      </div>
      <div className="logo-wrapper">
        <img src={logo5} alt="Logo 5" className="logo5" />
      </div>
      </div>
    </div>
  );
};

export default Logos;