import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const redirectToTypeform = () => {
    window.open('https://calendly.com/kathan-2bu/30min', '_blank');
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-title">
        <Link to="/" className='navbar-link'>Standard Service</Link>
      </div>
      <div className="mobile-title">
         <div className="logo">
         <Link to="/" className="mobile-title-link">Standard Service</Link>
      </div>
</div>
      <div className={`buttons-container${menuOpen ? ' open' : ''}`}>
        {/* <Link to="/employees" className="custom-btn">
          What We Do
        </Link> */}
        {/* <Link to="/employers" className="custom-btn">
          Login
        </Link> */}
        <div className="get-started-btnn" onClick={redirectToTypeform} >Get Started</div>
      </div>
      <div className="menu-toggle" onClick={handleMenuClick}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  );
};

export default Navbar;