import React from 'react';
import Footer from '../components/Footer';
import Icon1 from '../assets/icon1.svg';
import Icon2 from '../assets/icon2.svg';
import Icon3 from '../assets/icon3.svg';
import MockImage from '../assets/mock.png';
import MockImage1 from '../assets/mock1.png';
import MockImage2 from '../assets/mock2.png';
import MockImage3 from '../assets/mock3.png';
import MockImage4 from '../assets/mock4.png';
import MockImage5 from '../assets/mock5.png';
import MockImage6 from '../assets/mock6.png';
import Icon4 from '../assets/icon4.svg';
import Icon5 from '../assets/icon5.svg';
import Icon6 from '../assets/icon6.svg';
import Logos from '../components/Logos';

const Employees = () => {
  const redirectToTypeform = () => {
    window.open('https://fg3489c1ke9.typeform.com/to/b1PqgAFk', '_blank');
  };

  return (
    <>
    <div className="hero">
      <div className="hero-content">
        <h1 className="hero-title">Empower Your Employees</h1>
        <p className="hero-subtitle">Boost Employee Morale, Retention, and Financial Wellness with Our Innovative Earned Wage Access Solution</p>
        <button className="get-touched-btn" onClick={redirectToTypeform} >Get In Touch</button>
      </div>
      <Logos />
    </div>
    <div className="benefits-top">
      <img src={MockImage4} alt="Mock" className="mock-image3" />
      <div className="benefits-content">
        <h2 className='benefit-title'>Our Benefits</h2>
        <div className="benefits-list">
          <div className="benefit-item">
            <img src={Icon1} alt="Icon 1" className="benefit-icon" />
            <h3>Modular And Optional Payroll</h3>
            <p>We can handle your entire payroll or be a simple add on.</p>
          </div>
          <div className="benefit-item">
            <img src={Icon2} alt="Icon 2" className="benefit-icon" />
            <h3>Ease Your Cashflow For Flexible Needs</h3>
            <p>We can help select partners ease their payroll on a bi-weekly or monthly cycle.</p>
          </div>
          <div className="benefit-item">
            <img src={Icon3} alt="Icon 3" className="benefit-icon" />
            <h3>Increased Satisfaction</h3>
            <p>Uplift your team's morale by providing immediate access to their hard-earned wages.</p>
          </div>
        </div>
        <img src={MockImage5} alt="Mock" className="mock-image4" />
      </div>
    </div>
    <div className="benefits-bottom">
      <h2 className='benefit-title'>How It Works</h2>
      <div className="benefits-list">
      <div className="benefit-item">
            <img src={Icon4} alt="Icon 4" className="benefit-icon" />
            <h3>Finish Sign Up And Onboarding</h3>
            <p>Submit the required information and get approved.</p>
          </div>
          <div className="benefit-item">
            <img src={Icon5} alt="Icon 5" className="benefit-icon" />
            <h3>Run Timekeeping And Payroll</h3>
            <p>We'll take care of timekeep, payroll, taxes, and everything inbetween.</p>
          </div>
          <div className="benefit-item">
            <img src={Icon6} alt="Icon 6" className="benefit-icon" />
            <h3>Sit Back And Focus On What Matters</h3>
            <p>That's it just sign up, finish onboarding and let us handle the rest.</p>
          </div>
      </div>
      <img src={MockImage6} alt="Mock" className="mock-image3" />
      <button className="learn-more-button" onClick={redirectToTypeform} >Learn More</button>
    </div>
    <Footer />
    </>
  );
};

export default Employees;