import React from 'react';
import './Hero.css';
import Icon1 from '../assets/icon1.svg';
import Icon2 from '../assets/icon2.svg';
import Icon3 from '../assets/icon3.svg';
import mapImage from '../assets/Map.png';

const Hero = () => {
  // Replace 'https://your-typeform-link.com' with the actual link
  const redirectToTypeform = () => {
    window.open('https://calendly.com/kathan-2bu/30min', '_blank');
  };

  return (
    <div className="hero">
      <div className="hero-content">
        <h1 className="hero-title">AI Calls as Natural as Human Conversation</h1>
        <p className="hero-subtitle">
        Take sales to new heights, optimize onboarding, enhance customer support, and more. Allow our AI to handle your calls for unprecedented productivity.       
         </p>
        <button className="get-started-btn" onClick={redirectToTypeform}>Book Demo</button>
      </div>
      <div className="benefits-list">
          <div className="benefit-item">
            <img src={Icon1} alt="Icon 1" className="benefit-icon" />
            <h3>Low Latency</h3>
            <p> Human like response time, pioneering pace in AI.</p>
          </div>
          <div className="benefit-item">
            <img src={Icon2} alt="Icon 2" className="benefit-icon" />
            <h3>Emotional Queues</h3>
            <p>Exceptionally emotive AI, fostering authentic, humane exchanges.</p>
          </div>
          <div className="benefit-item">
            <img src={Icon3} alt="Icon 3" className="benefit-icon" />
            <h3>Contextual Agents</h3>
            <p>Self-adaptive learning and memory. You sit back, relaxed!</p>
          </div>
        </div>
    </div>
  );
};

export default Hero;