import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import BenefitsSection from '../components/BenefitsSection';
import MapSection from '../components/MapSection'; // Import MapSection
import Logos from '../components/Logos';
import Footer from '../components/Footer';

const LandingPage = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <Hero />
      <BenefitsSection />
      
      {/* <Logos /> */}
      <Footer />
    </div>
  );
};

export default LandingPage;